import { Content } from "./homeElements";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateBanner } from "../../data/banners/bannerActions";
import { UploadImage } from "../../components";
import UploadComponent from "../../components/docsUploadCard";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBanner());
  }, [dispatch]);

  return <Content />;

  // <UploadComponent />;

  //

  // <UploadImage />;
};
export default Home;
