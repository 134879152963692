import { KeyboardArrowDownIcon, ExpandLess, ExpandMore } from "../assets";
import {
  Box,
  Button,
  Stack,
  Menu,
  MenuItem,
  Typography,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Badge,
  styled,
  Tooltip,
} from "@mui/material";

import React, { useCallback } from "react";
import LetterAvatar from "./letterAvatar";
import { logout } from "../data/firebase";
import { routePaths } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";

const StyledBadge = styled(Badge)(({ theme, activated }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: activated === "true" ? "#44b700" : "#FF9966",
    color: activated === "true" ? "#44b700" : "#FF9966",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const profileMenu = [
  { name: "Acccount", path: "/profile/account" },
  // { name: "My Learning", path: "/profile/learning" },
  { name: "Help", path: routePaths.help },
  { name: "Log out", path: routePaths.executive },
];

const ProfileNav = ({ onClick = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user: localUser } = useSelector((state) => state.auth);
  const MySwal = withReactContent(Swal);
  const name =
    localUser.firstName && localUser.lastName
      ? `${localUser?.firstName} ${localUser?.lastName}`
      : "Unidentified User";
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const showLogoutDialog = useCallback(() => {
    Swal.fire({
      title: "Sign out",
      text: "Are you sure to sign out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#F30813",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        logout(dispatch);
        Swal.fire({
          title: "Success",
          text: "Successfully signed out of GAMLS",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#000000",
        });
      }
    });
  }, [dispatch]);

  return (
    <Box mx={{ xs: 0, md: 2 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="dot"
          activated={localUser.activated ? "true" : "false"}
        >
          <LetterAvatar small name={name} />
        </StyledBadge>

        <Button
          aria-haspopup={"true"}
          aria-controls={"menu-appbar"}
          aria-label={"about"}
          onClick={(e) => setAnchorElUser(e.currentTarget)}
          sx={{
            my: 2,
            color: "black",
            display: "flex",
            textTransform: "initial",
            fontSize: "16px",
            mr: 2,
          }}
          endIcon={<ExpandMore />}
        >
          {"Profile"}
        </Button>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem
            disableRipple
            focusRipple={false}
            sx={{
              cursor: "auto",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleCloseUserMenu();
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LetterAvatar small name={name} />
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack ml={2}>
                <Typography variant={"body1"} fontWeight={"bold"}>
                  {name}
                </Typography>
                <Typography variant={"body2"}>{localUser?.memberId}</Typography>
              </Stack>
            </Box>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleCloseUserMenu();
              navigate("/profile/account");
            }}
          >
            {!localUser.activated || localUser.restricted ? (
              <Tooltip title="Your account is restricted. Kindly ensure you have made payments to the Momo number 0558521561 - Ghana Association of Medical Laboratory Scientists. (MLS/ MLSD - GHc50.00, Technicians - GHc40.00, Assistants - 30.00).  Kindly specify your payment type in the reference (Dues payment / Registration payment ) ">
                <Typography textAlign="center">
                  {!localUser.activated && "Account not activated"}
                  {localUser.restricted && "Payment required"}
                </Typography>
              </Tooltip>
            ) : (
              <Typography textAlign="center">Account</Typography>
            )}
          </MenuItem>

          {/* <MenuItem
            onClick={() => {
              handleCloseUserMenu();
              navigate("/profile/learning");
            }}
          >
            <Typography textAlign="center">{"My Learning"}</Typography>
          </MenuItem> */}
          <Divider />
          {/* <MenuItem
            onClick={() => {
              handleCloseUserMenu();
              navigate(routePaths.help);
            }}
          >
            <Typography textAlign="center">{"Help"}</Typography>
          </MenuItem> */}

          <MenuItem
            onClick={() => {
              handleCloseUserMenu();
              showLogoutDialog();
            }}
          >
            <Typography textAlign="center">{"Log out"}</Typography>
          </MenuItem>
        </Menu>
      </Stack>

      <Stack sx={{ display: { xs: "flex", md: "none" } }}>
        <React.Fragment>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <LetterAvatar smaller name={name} />
            </ListItemIcon>
            <ListItemText primary={"Profile"} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                disableRipple
                sx={{
                  pl: 4,
                  borderBottom: "1px solid #cecece",
                }}
              >
                <ListItemText primary={name} secondary={localUser.id} />
              </ListItemButton>
              {profileMenu.map((p, idx) => {
                const index = idx + 1;
                return (
                  <ListItemButton
                    key={p.name}
                    sx={{
                      pl: 4,
                      borderBottom:
                        index % 2 === 0 && index !== profileMenu.length
                          ? "1px solid #cecece"
                          : "none",
                    }}
                    onClick={() => {
                      onClick();
                      index === profileMenu.length
                        ? showLogoutDialog()
                        : navigate(p.path);
                    }}
                  >
                    <ListItemText primary={p.name} />
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </React.Fragment>
      </Stack>
    </Box>
  );
};

export default ProfileNav;
