import { Add } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { Image } from "mui-image";

import React, { useCallback, useState } from "react";

const FileUpload = ({ placeholder, formik }) => {
  const inputFileRef = React.useRef();

  const [academicProof, setAcademicProof] = useState(null);
  const styles = {
    card_style: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      bgcolor: "#F2F2F2",
    },
  };

  const handleOnClick = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  return (
    <Box>
      <Card elevation={0} sx={{ height: 50 }}>
        <CardActionArea onClick={handleOnClick}>
          <input
            style={{ display: "none" }}
            // accept="image/*"
            type="file"
            ref={inputFileRef}
            onChangeCapture={(event) => {
              if (event.currentTarget.files && event.currentTarget.files[0]) {
                formik.setFieldValue(
                  "academicProof",
                  event.currentTarget.files[0]
                );
                setAcademicProof(
                  URL.createObjectURL(event.currentTarget.files[0])
                );
              }
            }}
          />

          <CardContent
            sx={{
              ...styles.card_style,
              alignItems: "center",
              justifyContent: "center",
              // height: 140,
              // width: 140,
              p: 0,
            }}
          >
            <Add />
          </CardContent>
        </CardActionArea>
        {formik.values.academicProof ? (
          <Typography>{formik.values.academicProof.name}</Typography>
        ) : null}
      </Card>
      <FormHelperText
        error={
          formik.touched.academicProof && Boolean(formik.errors.academicProof)
        }
      >
        {`Please add a ${placeholder}`}
      </FormHelperText>
    </Box>
  );
};

export default FileUpload;
