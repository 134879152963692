import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { VoteSuccess, ArrowBackIos, ArrowForwardIos } from "../../assets";
import { ElectionRadio, LetterAvatar } from "../../components";
import { routePaths } from "../../constants";

const KeyValueText = ({ isVerified, keyd = "", value = "" }) => (
  <Typography variant={"body1"} fontWeight={"bold"}>
    {keyd}
    <Typography
      component={"span"}
      color={
        isVerified && keyd.toLowerCase().includes("status")
          ? "primary"
          : "inherit"
      }
    >
      {value}
    </Typography>
  </Typography>
);

const UserCard = ({ detailed, user, isVerified }) => {
  const fullName = `${user?.firstName} ${user?.lastName}`;
  return (
    <Card sx={{ borderRadius: 5 }}>
      <CardContent>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 3, md: 6 }}
          alignItems={"center"}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "none" } }}
            />
          }
        >
          <LetterAvatar name={fullName} small={!detailed} />

          <Stack spacing={2} alignItems={{ xs: "center" }}>
            <KeyValueText
              isVerified={isVerified}
              keyd={"Member Name: "}
              value={fullName}
            />

            <KeyValueText
              isVerified={isVerified}
              keyd={"Member ID: "}
              value={user?.id}
            />

            {detailed && (
              <>
                <KeyValueText
                  isVerified={isVerified}
                  keyd={"Region: "}
                  value={user?.region}
                />
                <KeyValueText
                  isVerified={isVerified}
                  keyd={"Member Status: "}
                  value={isVerified ? "Verified" : "Not verified"}
                />
              </>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const ElectionLanding = ({ isVerified, user }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: { xs: 8, md: 16 },
      }}
    >
      <Stack spacing={{ xs: 6, md: 12 }} alignItems={"center"}>
        <UserCard detailed user={user} isVerified={isVerified} />

        <Button
          disableElevation
          variant={"contained"}
          disabled={!isVerified}
          sx={{ width: "fit-content" }}
          endIcon={<ArrowForwardIos sx={{ width: 12, height: 12 }} />}
          LinkComponent={NavLink}
          to={"/election-hq/voting"}
        >
          {"Proceed to vote"}
        </Button>
      </Stack>
    </Box>
  );
};

const VotingPage = ({ isVerified, user }) => {
  return (
    <Box py={6}>
      <Stack spacing={6}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={6}>
          <Box flexGrow={0.5}>
            <UserCard isVerified={isVerified} user={user} />
          </Box>
          <Box
            flexGrow={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              background:
                "transparent linear-gradient(249deg, #F30813 0%, #7A040A 100%)",
              px: 12,
              py: 3,
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            <Typography variant={"h3"} fontWeight={"bold"}>
              {"President 2022"}
            </Typography>
          </Box>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant={"h5"} fontWeight={"bold"}>
            {"Select Your Candidate"}
          </Typography>

          <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            alignItems={{ xs: "flex-end", md: "center" }}
            spacing={2}
          >
            <Typography variant={"body1"} whiteSpace={"nowrap"}>
              {"Voting ends 22-10-2022, 3:00PM"}
            </Typography>

            <Card elevation={0}>
              <CardContent>
                <Typography variant={"body1"} fontWeight={"bold"}>
                  {"Timer: "}
                  <Typography
                    component={"span"}
                    color={"primary"}
                    fontWeight={"bold"}
                  >
                    {"40sec"}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        </Stack>

        <Box>
          <ElectionRadio />
        </Box>

        <Stack direction={"row"} spacing={2} justifyContent={"center"}>
          <Button
            disableElevation
            variant={"contained"}
            sx={{ width: "fit-content", bgcolor: "black" }}
            startIcon={<ArrowBackIos sx={{ width: 12, height: 12 }} />}
          >
            {"Cancel"}
          </Button>
          <Button
            disableElevation
            variant={"contained"}
            sx={{ width: "fit-content" }}
            endIcon={<ArrowForwardIos sx={{ width: 12, height: 12 }} />}
            LinkComponent={NavLink}
            to={"/election-hq/confirm-vote"}
          >
            {"Submit vote"}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

const ConfirmVote = ({ user }) => {
  return (
    <Box py={6}>
      <Stack spacing={6}>
        <Stack sx={{ justifyContent: "center", px: { xs: "15px", md: "30%" } }}>
          <UserCard user={user} />
        </Stack>

        <Typography variant={"h5"} textAlign={"center"}>
          {"CONFIRM YOUR VOTE FOR GAMLS Executive Council 2022"}
        </Typography>

        <Stack direction={"row"} spacing={2} justifyContent={"center"}>
          <Button
            disableElevation
            variant={"contained"}
            sx={{ width: "fit-content", bgcolor: "black" }}
            startIcon={<ArrowBackIos sx={{ width: 12, height: 12 }} />}
          >
            {"Cancel"}
          </Button>
          <Button
            disableElevation
            variant={"contained"}
            sx={{ width: "fit-content" }}
            endIcon={<ArrowForwardIos sx={{ width: 12, height: 12 }} />}
            LinkComponent={NavLink}
            to={"/election-hq/vote-status"}
          >
            {"Confirm Vote"}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

const Success = ({ user }) => {
  return (
    <Box py={6}>
      <Stack spacing={{ xs: 2, md: 6 }}>
        <Stack sx={{ justifyContent: "center", px: { xs: "15px", md: "30%" } }}>
          <UserCard user={user} />
        </Stack>

        <Box>
          <Typography variant={"h5"} textAlign={"center"} color={"primary"}>
            {"Congratulations!!!"}
          </Typography>
          <Typography variant={"body1"} textAlign={"center"} color={"primary"}>
            {"Your vote is Successful"}
          </Typography>
        </Box>

        <Stack spacing={{ xs: 2, md: 4 }} alignItems={"center"}>
          <Box>
            <Image src={VoteSuccess} />
          </Box>
          <Button
            disableElevation
            variant={"contained"}
            sx={{ width: "fit-content" }}
            endIcon={<ArrowForwardIos sx={{ width: 12, height: 12 }} />}
            LinkComponent={NavLink}
            to={routePaths.home}
          >
            {"Back to home"}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export const Content = ({ isVerified, id }) => {
  const { user } = useSelector((state) => state.auth);

  const renderComponent = () => {
    switch (id) {
      case "landing":
        return <ElectionLanding isVerified={isVerified} user={user} />;
      case "voting":
        return <VotingPage isVerified={isVerified} user={user} />;
      case "confirm-vote":
        return <ConfirmVote user={user} />;
      case "vote-status":
        return <Success user={user} />;
      default:
        return <ElectionLanding isVerified={isVerified} user={user} />;
    }
  };
  return (
    <Box>
      <Box
        sx={{
          px: { xs: "15px", md: "140px" },
        }}
      >
        {renderComponent()}
      </Box>
    </Box>
  );
};
