import React, { useRef, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../data/firebase"; // Assuming you have your Firebase config setup
import ReactCrop, {
  convertToPixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "./setCanvasPreview";
import "react-image-crop/dist/ReactCrop.css";
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Grid,
} from "@mui/material";
import Image from "mui-image";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;
function UploadImage() {
  const imgRef = useRef(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState();
  const previewCanvasRef = useRef(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!croppedImageUrl) {
      setError("Please crop an image");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const storageRef = ref(storage, `passport-images/${Date.now()}.jpg`);
      const imageBlob = await fetch(croppedImageUrl).then((res) => res.blob());
      await uploadBytes(storageRef, imageBlob);
      const url = await getDownloadURL(storageRef);
      setCroppedImageUrl(url);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "px",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };
  return (
    <Box
      sx={{
        // display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 5,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Upload Passport Image
      </Typography>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {image && (
        <Box sx={{ mt: 3, width: "200px", height: "200px" }}>
          <ReactCrop
            crop={crop}
            maxHeight={200}
            maxWidth={200}
            onChange={(pixelCrop, percentCrop) => setCrop(pixelCrop)}
            onLoad={onImageLoad}
            aspect={1}
          >
            <Image
              ref={imgRef}
              src={image}
              alt="Upload"
              style={{ width: "200px", height: "200px" }}
            />
          </ReactCrop>
        </Box>
      )}

      <div>{JSON.stringify(image)}</div>
      <div>{JSON.stringify(crop)}</div>
      {/* {croppedImageUrl && (
        <Box sx={{ mt: 3 }}>
          <img src={croppedImageUrl} alt="Cropped Image" width="200" />
        </Box>
      )} */}
      {/* {crop && ( */}
      <canvas
        ref={previewCanvasRef}
        className="mt-4"
        style={{
          // display: "none",
          border: "1px solid black",
          objectFit: "contain",
          width: 150,
          height: 150,
        }}
      />
      {/* )} */}

      <Button
        variant="contained"
        onClick={() => {
          setCanvasPreview(
            imgRef.current, // HTMLImageElement
            previewCanvasRef.current, // HTMLCanvasElement
            convertToPixelCrop(
              crop,
              imgRef.current.width,
              imgRef.current.height
            )
          );
          setCroppedImageUrl(previewCanvasRef.current.toDataURL());
        }}
        sx={{ mt: 3 }}
      >
        Crop Image
      </Button>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          {croppedImageUrl && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Cropped Image Preview
              </Typography>
              <img
                src={croppedImageUrl}
                alt="Cropped Image"
                width="200"
                style={{ maxWidth: "100%" }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {croppedImageUrl && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Canvas Preview
              </Typography>
              <canvas
                ref={previewCanvasRef}
                className="mt-4"
                style={{
                  // display: "none",
                  border: "1px solid black",
                  objectFit: "contain",
                  width: 150,
                  height: 150,
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={isLoading || !croppedImageUrl}
        sx={{ mt: 3 }}
      >
        {isLoading ? <CircularProgress size={24} sx={{ mr: 2 }} /> : "Upload"}
      </Button>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
}

export default UploadImage;
