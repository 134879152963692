import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState } from "react";
// import { v4 as uuidv4 } from "uuid";

import { showAlert } from "../utils/swalUtlis";
import { db, storage } from "../data/firebase";

const useFirebaseUpload = () => {
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updatedUrls, setUpdatedUrls] = useState({});

  const beginWithFileUpload = async (uploadItems, parent, id) => {
    setLoading(true);

    const uploadPromises = uploadItems.map((uploadItem) => {
      const storageRef = ref(
        storage,
        `/${parent}/${uploadItem.location}/${id}`
      );
      const uploadTask = uploadBytesResumable(storageRef, uploadItem.item);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                setPercent(
                  Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  )
                );
                break;
              default:
                console.log("Upload status unknown");
                break;
            }
          },
          (error) => {
            console.error("Upload failed:", error);
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => {
                setUpdatedUrls((prevUrls) => ({
                  ...prevUrls,
                  [uploadItem.key]: url,
                }));
                resolve({ [uploadItem.key]: url });
              })
              .catch((error) => {
                console.error("Failed to get download URL:", error);
                reject(error);
              });
          }
        );
      });
    });

    try {
      const urls = await Promise.all(uploadPromises);
      const mergedUrls = urls.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      const updatedData = {
        // ...values,
        ...mergedUrls,
      };

      console.log(updatedData);
      await beginDataUpload(updatedData, parent, id);
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      setLoading(false);
    }
  };

  const beginDataUpload = async (data, parent, docid) => {
    setLoading(true);
    try {
      const dataRef = doc(db, `${parent}`, docid);
      await setDoc(
        dataRef,
        {
          ...data,
          date: serverTimestamp(),
        },
        { merge: true }
      )
        .then(() => {
          setLoading(false);
          showAlert(true);
        })
        .catch(() => {
          showAlert(false);
          setLoading(false);
        });
    } catch {
      showAlert(false);
      setLoading(false);
    }
  };

  // const beginDataEdit = async (
  //   values,

  //   parent,
  //   merge = false,
  //   hasImage = true
  // ) => {
  //   if (hasImage && typeof values.img_url !== "string") return;

  //   setLoading(true);

  //   try {
  //     const dataRef = doc(db, `${parent}/${values.id}`);
  //     await setDoc(
  //       dataRef,
  //       { ...values, date_modified: serverTimestamp() },
  //       { merge: merge }
  //     )
  //       .then(() => {
  //         setLoading(false);
  //         showAlert(true);
  //       })
  //       .catch(() => {
  //         showAlert(false);
  //       });
  //   } catch {
  //     showAlert(false);
  //     setLoading(false);
  //   }
  // };

  return {
    beginWithFileUpload,
    beginDataUpload,
    loading,
    percent,
    // beginDataEdit,
  };
};

export default useFirebaseUpload;
