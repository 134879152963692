import InputText from "../../../components/form/inputText";
import CustomSelect from "../../../components/form/customSelect";
import { regions } from "../../../constants";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import DocUpload from "../../../components/docUpload";

export const ContactDetails = ({ formik }) => {
  const options = useMemo(() => ["Ghana"], []);
  // Enable this to access all countries
  // const options = useMemo(() => countryList().getLabels(), []);

  return (
    <>
      <CustomSelect
        helperText={formik.errors.region}
        placeholder={"Region"}
        data={regions}
        onSelect={(e) => formik.setFieldValue("region", e)}
        error={formik.touched.region && Boolean(formik.errors.region)}
        defaultValue={formik.values.region}
      />
      <CustomSelect
        placeholder={"Nationality"}
        defaultValue={formik.values.nationality}
        data={options}
        // Enable this to access all countries
        // data={options}
        onSelect={(e) => formik.setFieldValue("nationality", e)}
        helperText={formik.errors.nationality}
        error={formik.touched.nationality && Boolean(formik.errors.nationality)}
      />
      <InputText
        placeholder={"Permanent/Home Address"}
        value={formik.values.address}
        onChange={formik.handleChange}
        helperText={formik.errors.address}
        name={"address"}
        error={formik.touched.address && Boolean(formik.errors.address)}
      />

      <InputText
        placeholder={"Mobile/Cell Phone No"}
        value={formik.values.phone}
        onChange={formik.handleChange}
        helperText={formik.errors.phone}
        name={"phone"}
        type={"phone"}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
      />
    </>
  );
};
