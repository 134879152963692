import { Box, Container, Stack, Typography } from "@mui/material";

import React, { useCallback } from "react";
import { auth } from "../../data/firebase";
import TransitionAlerts from "../../components/transitionAlerts";

import { signInWithEmailAndPassword } from "firebase/auth";
import InputText from "../../components/form/inputText";
import { mapAuthCodeToMessage } from "../../utils/mapCodeToMessage";
import { PrimaryButton, TextButton } from "../../components/buttons";
import PasswordResetModal from "./passwordReset";
import { ImageUploadCard, PasswordField, UploadImage } from "../../components";
import ImageCropper from "../../components/imageUploadCard";

export const LoginContent = React.memo(
  ({ navigate, title, subtitle, showRegister }) => {
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [values, setValues] = React.useState({
      email: "",
      password: "",
    });

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          await signInWithEmailAndPassword(auth, values.email, values.password);
          setLoading(false);
        } catch (err) {
          console.log(err.code);
          const message = mapAuthCodeToMessage(err.code);
          setError(message);
          setLoading(false);
        }
      },
      [values.email, values.password]
    );

    const handleChange = (value, type) => {
      setValues({ ...values, [type]: value });
      setError("");
    };

    return (
      <Stack
        component={"form"}
        spacing={{ xs: 3, md: 3 }}
        onSubmit={handleSubmit}
        p={{ xs: "10px", md: "8%" }}
      >
        <Box>
          <Typography
            sx={{
              m: { md: "10px 0px" },
            }}
            variant={"h5"}
            fontWeight={"bold"}
          >
            {title}
          </Typography>

          <Typography gutterBottom variant={"body2"}>
            {subtitle}
          </Typography>
        </Box>

        <InputText
          placeholder={"Email"}
          type={"email"}
          name={"email"}
          value={values.username}
          onChange={(e) => handleChange(e.target.value, "email")}
        />

        <PasswordField
          mode={"input"}
          value={values.password}
          name={"password"}
          placeholder={"Password"}
          onChange={(e) => handleChange(e.target.value, "password")}
        />

        <TransitionAlerts
          message={error}
          severity={"error"}
          onClose={() => setError("")}
        />

        <TextButton bold onClick={handleOpenModal}>
          Forgotten Password
        </TextButton>

        <PasswordResetModal open={openModal} onClose={handleCloseModal} />
        <PrimaryButton type={"submit"} loading={loading}>
          Login
        </PrimaryButton>

        {showRegister && (
          <Stack direction={"row"} alignItems={"center"}>
            <Typography sx={{ textTransform: "initial" }} variant={"button"}>
              Don't have an account?
            </Typography>
            <TextButton
              color={"primary"}
              onClick={() => navigate("/login?reg_stg=0")}
            >
              Register here
            </TextButton>
          </Stack>
        )}
      </Stack>
    );
  }
);
