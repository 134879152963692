import { Add } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import { Image } from "mui-image";

import React, { useCallback, useState } from "react";

const DocUpload = ({ placeholder, formik }) => {
  const inputFileRef = React.useRef();

  const [image, setImage] = useState(null);
  const styles = {
    card_style: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      bgcolor: "#F2F2F2",
    },
  };

  const handleOnClick = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  return (
    <Box>
      <Card elevation={0} sx={{ height: 140, width: 140 }}>
        <CardActionArea onClick={handleOnClick}>
          <input
            style={{ display: "none" }}
            accept="image/*"
            type="file"
            ref={inputFileRef}
            onChangeCapture={(event) => {
              if (event.currentTarget.files && event.currentTarget.files[0]) {
                formik.setFieldValue(
                  "profileImg",
                  event.currentTarget.files[0]
                );
                setImage(URL.createObjectURL(event.currentTarget.files[0]));
              }
            }}
          />

          <CardContent
            sx={{
              ...styles.card_style,
              alignItems: "center",
              justifyContent: "center",
              height: 140,
              width: 140,
              p: 0,
            }}
          >
            {image || formik.values.profileImg ? (
              <Image
                src={image || formik.values.profileImg}
                sx={{
                  width: 140,
                  height: 140,
                  objectFit: "cover",
                }}
              />
            ) : (
              <Add />
            )}
          </CardContent>
        </CardActionArea>
      </Card>
      <FormHelperText
        error={formik.touched.profileImg && Boolean(formik.errors.profileImg)}
      >
        {`Please add a ${placeholder}`}
      </FormHelperText>
    </Box>
  );
};

export default DocUpload;
